import React from 'react'
import {useStaticQuery, graphql, Link } from "gatsby"
import {Container , Card} from 'react-bootstrap'
import * as styles from '../assets/Newhome.module.css'
import Img from 'gatsby-image'


const MainPage = () =>{

    const data = useStaticQuery(graphql`
    {
        allStrapiMainPages {
          nodes {
            Title
            Mainpagecomponent {
              link
              image {
                childImageSharp {
                  fixed(height: 200) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
        allStrapiNewAnnouncements {
          nodes {
            NewAnnouncementComponent {
              link
            }
            Title
          }
        }
        allStrapiNewEvents {
          nodes {
            Title
            NewEventComponent {
              link
            }
          }
        }
      }
    `)


    const homepage = data.allStrapiMainPages.nodes

    const announcement = data.allStrapiNewAnnouncements.nodes[0]
    const announcementComponent = announcement.NewAnnouncementComponent

    const event = data.allStrapiNewEvents.nodes[0]
    const eventComponent = event.NewEventComponent
    
    console.log(data)
    console.log(homepage)

    let MainPage = homepage.map((element,index)=>{
    
        return(
            <Card className={styles.card}>
                <p className={styles.p}>{element.Title}</p>
                {element.Mainpagecomponent.map((newelement,newindex)=>{
                    
                    return(
                        <div>
                           {newelement.image !== null ?<div className={styles.mainpageimage}><Img fixed={newelement.image.childImageSharp.fixed} style={{border:"1px solid black"}}/></div> :''}
                           <ul><li style={{color:"rgb(10, 0, 150)"}}><Link to={`/${newelement.link}`} state={{index: newindex}} className={styles.link}>{newelement.link}</Link></li></ul>
                        </div>
                    )
                })}
            </Card>
        )
    })

    let NewAnnouncement = announcementComponent.map((element,index)=>{
      return(
        <div>
          {index < 3 ? <ul><li style={{color:"rgb(10, 0, 150)"}}><Link to={`/${element.link}`} state={{index: index}} className={styles.link}>{element.link}</Link></li></ul> : ''}
        </div>
      )
    })

    let NewEvent = eventComponent.map((element,index)=>{
      return(
        <div>
          {index < 3 ? <ul><li style={{color:"rgb(10, 0, 150)"}}> <Link to={`/${element.link}`} state={{index: index}} className={styles.link}>{element.link}</Link></li></ul> : ''}
        </div>
      )
    })

    return(
        <div>
            <Container>
                <div className='row'>
                {MainPage}
                
   
                <Card className={styles.card}>
                <p className={styles.p}>{announcement.Title}</p>
                {NewAnnouncement}
                <div>
                 <Link to="/Announcement" className={styles.all}>All Announcements</Link>
                </div>
                </Card>
   
                <Card className={styles.card}>
                <p className={styles.p}>{event.Title}</p>
                {NewEvent}
                <div>
                 <Link to="/event" className={styles.all}>All Events</Link>
                </div>
                </Card>
   
                </div>
            </Container>
        </div>
    )
}

export default MainPage

