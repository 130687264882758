import * as React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import MainPage from '../components/mainpage'


export default function Main() {

  return (
   // <Router>
    <Layout >
      <SEO title="ISRID" />
      <main >
       <MainPage />
      </main>{' '}
    </Layout>
    //</Router>
  )
}
